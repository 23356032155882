@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";





































































































































































































































.extra-activity {
  &__header {
    ::v-deep .header {
      background-color: #1F3161;

      &__title {
        margin-right: $size-s;
      }
    }
  }
}
